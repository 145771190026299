import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import user from './user';
import vCard from './vCard';
import metrics from './metrics';
import nps from './nps'
import webStore from './web'

import {auth} from '../firebase';

const store = new Vuex.Store({
  state: {
    loader:false
  },
  mutations: {
    changeLoader(state, load){
      state.loader = load;
    }
  },

  actions: {

    checkAuth({dispatch, commit}){
      auth.onAuthStateChanged(async function(user){
        if(user){
          commit("user/setUser", user);
          try{
            await dispatch("vCard/getAllCards");
            await dispatch("metrics/getAllVisitCards");
            await dispatch("nps/getAllPolls");
            await dispatch("webStore/getAllStories");
            await dispatch("webStore/getAllBrands");
            await dispatch("webStore/getAllProducts");
            await dispatch("vCard/getTechnicalSheets");
            await dispatch("vCard/getAllCatalogues")
            await dispatch("vCard/getAllBranches")
          } catch(e){
            console.log(e)
          }
        } else {
          commit("user/setUser", null);
          commit("vCard/setCards", []);
          commit("vCard/setCardListener", () => {});      
          commit("metrics/setVisitsMetrics", []);             
          commit("metrics/setCardsVisitsListener", () => {});
          commit("nps/setPolls", () => {});
          commit("vCard/setCatalogues", []);
        }
      })
    }
  },
  modules: {
    user,
    vCard,
    metrics,
    nps,
    webStore
  }
})


store.dispatch("checkAuth");


export default store;

