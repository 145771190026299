import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database"

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY_PROD,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket:  process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId:  process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

firebase.initializeApp(firebaseConfig);
    
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database()  
export { db, auth, firebase, storage, database };
