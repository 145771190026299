import Vue from "vue";
import VueRouter from "vue-router";
const Register = () =>
  import(/* webpackChunkName: "Login" */ "../views/register/index.vue");
const Dashboard = () =>
  import(/* webpackChunkName: "Dashboard" */ "../views/dashboard/index.vue");
const productCreate = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "../views/dashboard/WebPage/views/createProduct.vue"
  );
  const productUpdate = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "../views/dashboard/WebPage/views/updateProduct.vue"
  );
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Register",
    component: Register,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product/create",
    name: "product-create",
    component: productCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product/update/:id",
    name: "product-update",
    component: productUpdate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/vcard/create',
    name: 'vcard-create',
    component: () => import('../views/dashboard/vCard/createVcard'),
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/vcard/update/:id',
    name: 'vcard-update',
    component: () => import('../views/dashboard/vCard/createVcard'),
    meta: {
        requiresAuth: true
    },
    props: (route) => {
        return {
          id: route.params.id,
        };
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // Requires auth & no user
  if (requiresAuth && !(await store.dispatch("user/getCurrentUser"))) {
    next({ name: "Register" });
    // No requires auth and user (auth)
  } else if (!requiresAuth && (await store.dispatch("user/getCurrentUser"))) {
    next({ name: "Dashboard" });
  } else {
    // Anything else
    next();
  }
});

export default router;
