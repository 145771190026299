import { db, database } from "../firebase";

const state = {
  polls: [],
};

const getters = {
  getPolls(state) {
    return { ...state.polls };
  },

  getPoll: (state) => (id) => {
    const poll = state.polls.find((e) => e.id === id);
    return { ...poll };
  },
};

const mutations = {
  setPollListener(state, listener) {
    if (listener) {
      state.pollListener = listener;
    } else {
      state.pollListener();
    }
  },

  setPolls(state, cards) {
    state.polls = cards;
  },

  createPoll(state, { pollData, id }) {
    pollData.id = id;
    state.polls.push(pollData);
  },

  removePoll(state, id) {
    state.polls = state.polls.filter((e) => e.id !== id);
  },

  updatePoll(state, poll) {
    const index = state.polls.map((e) => e.id).indexOf(poll.id);
    state.polls[index] = poll;
  },
};

const actions = {
  async createPoll({ commit }, poll) {
    const docRefCard = database.ref("surveys");
    const docPush = await docRefCard.push(poll);
    commit("createPoll", { pollData: { ...poll }, id: docPush.key });
  },

  async getAllPolls({ commit }) {
    try {
      const docRefPoll = (await database.ref().child("surveys").get()).val();
      const polls = [];
      for (let id of Object.keys(docRefPoll)) {
        polls.push({
          id,
          ...docRefPoll[id],
        });
      }
      commit("setPolls", polls);
    } catch (e) {
      commit("setPolls", [])
    }
  },

  async deletePoll({ commit }, { pollId }) {
    await database.ref(`surveys/${pollId}`).remove();
    commit("removePoll", pollId);
  },

  async updateCard({ commit }, { poll, status }) {
    await database.ref("surveys").child(poll.id).child("status").set(status);
    poll.status = status;
    commit("updatePoll", { ...poll });
  },

  async getCard({ getters, commit }, cardId) {
    let card = getters["getCard"](cardId);
    if (!card) {
      card = await db.collection("employee").doc(cardId).get();

      if (!card.exist) throw new Error("No se encontro la Vcard");
      card = card.data();
    }
    commit("setCurrentOperation", card);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
