import { storage } from "../firebase";


const uploadFile = async (fileId, file, folder, type, subdocument = '') => {
  const uploadPhoto = () => {
    let fileName = ''
    if(subdocument != '') {
      fileName = `${folder}/${subdocument}/${fileId}-${type}.${file.type.includes('video') ? 'mp4' : 'jpg'}`
    } 

    if(type === 'pdf') {
      fileName = `${folder}/${fileId}.pdf`;
    } else {
      fileName = `${folder}/${fileId}-${type}.jpg`;
    }
   
    return storage.ref(fileName).put(file);
  };

  const getDownloadUrl = (ref) => {
    return ref.getDownloadURL();
  };

  try {
    let upload = await uploadPhoto();
    return await getDownloadUrl(upload.ref);
  } catch (error) {
    throw new Error(error.message);
  }
};

export default uploadFile;
