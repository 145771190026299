import { db, storage, database } from "../firebase";
import uploadFile from "../helpers/uploadFile";
import { v4 as uuidv4 } from 'uuid';
import deleteFile from "../helpers/deleteFile";

let today = new Date();
const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
const state = {
    cards: [],
    cardsListener: () => { },
    currentId: "",
    cardEdit: {},
    showEditForm: false,
    technicals: [],
    catalogues: [],
    branches: []
};

const getters = {
    getCard: state => id => {
        return state.cards.find(el => el.id == id);
    },

    getCurrentId: state => {
        return state.currentId;
    },

    getCurrentCard: state => {
        return state.cardEdit;
    },

    getTechnicalSheets: state => {
        return state.technicals;
    },

    getAllCards: state => state.cards,

    getAllCatalogues: state => state.catalogues,

    getBranches: state => state.branches

};

const mutations = {
    setCardListener(state, listener) {
        if (listener) {
            state.cardsListener = listener;
        } else {
            state.cardsListener();
        }
    },

    setCards(state, cards) {
        state.cards = cards;
    },

    createCard(state, { cardData, id }) {
        cardData.id = id;
        state.cards.push(cardData);
    },

    removeCard(state, index) {
        state.cards.splice(index, 1);
    },

    updateCard(state, { index, cardData, id }) {
        cardData.id = id;
        state.cards[index] = cardData;
    },

    setCurrentOperation(state, payLoad) {
        state.cardEdit = payLoad;
    },

    showUpdate(state, id) {
        state.showEditForm = !state.showEditForm;
        state.currentId = id;
    },

    setTechnical(state, techincals) {
        state.technicals = techincals;
    },

    createTechnicalSheet(state, technicalSheet) {
        state.technicals = ([technicalSheet, ...state.technicals]);
    },

    deleteTechnicalSheet(state, id) {
        state.technicals = state.technicals.filter(story => story.id != id)
    },

    createCatalogue(state, catalogue) {
        state.catalogues = ([catalogue, ...state.catalogues]);
    },

    setCatalogues(state, catalogues) {
        state.catalogues = catalogues;
    },

    deleteCatalogues(state, id) {
        state.catalogues = state.catalogues.filter(catalogue => catalogue.id != id)
    },

    createBranch(state, branch) {
        state.branches = ([branch, ...state.branches]);
    },

    setBranches(state, branches) {
        state.branches = branches;
    },

    deleteBranch(state, id) {
        state.branches = state.branches.filter(branch => branch.id != id)
    },

};

const actions = {

    getNewCardId() {
        return db.collection("employee").doc();
    },

    async createCard(context, { 
        name, 
        job, 
        phone, 
        mail, 
        last, 
        sucursal, 
        description1, 
        description2, 
        image,
        sucursalPhone,
        sucursalAddress 
    }) {

        const docRefCard = await db.collection("employee").doc();
        const docRefVisitas = await db.collection("visitas").doc();

        docRefCard.set({
            name,
            job,
            phone,
            mail,
            last,
            sucursal,
            description1,
            description2,
            image,
            sucursalPhone,
            sucursalAddress,
            visitsId: docRefVisitas.id,
            createdAt: ` ${today.getDate()} ${months[today.getMonth()]} de ${today.getFullYear()}`,
            createdAtTime: `${today.getHours()} : ${today.getMinutes()} : ${today.getSeconds()}`
        })

        docRefVisitas
            .set({
                UserId: docRefCard.id,
                visits: 0,
                gmail: 0,
                wht: 0,
                phone: 0,
                in: 0,
                fb: 0,
                web: 0
            })

    },

    async getAllCards({ commit }) {
        const query = await db
            .collection("employee")
            .orderBy("name")
            .onSnapshot(doSnapshot);


        commit("setCardListener", query);

        function doSnapshot(querySnapshot) {

            querySnapshot.docChanges().forEach(change => {
                if (change.type === "added") {
                    commit("createCard", {
                        cardData: change.doc.data(),
                        id: change.doc.id
                    });
                }

                if (change.type === "modified") {
                    commit("updateCard", {
                        index: change.newIndex,
                        cardData: change.doc.data(),
                        id: change.doc.id
                    });
                }

                if (change.type === "removed") {
                    commit("removeCard", change.oldIndex);
                }

            });

        }
    },

    async deleteCard(context, { cardId, visitsId }) {
        await db
            .collection("employee")
            .doc(cardId)
            .delete();

        await db
            .collection("visitas")
            .doc(visitsId)
            .delete();
    },

    async updateCard(context, { 
        cardId, 
        name, 
        job, 
        phone, 
        mail, 
        last, 
        sucursal, 
        description1, 
        description2, 
        image,
        sucursalPhone,
        sucursalAddress
    }) {
        let user = {};
        if (name) user.name = name;
        if (job) user.job = job;
        if (phone) user.phone = phone;
        if (mail) user.mail = mail;
        if (last) user.last = last;
        if (sucursal) user.sucursal = sucursal;
        if (description1) user.description1 = description1;
        if (description2) user.description2 = description2;
        if (image) user.image = image;
        if(sucursalAddress) user.sucursalAddress = sucursalAddress;
        if(sucursalPhone) user.sucursalPhone = sucursalPhone
        
        await db
            .collection("employee")
            .doc(cardId)
            .update(user);
    },

    async getCard(_, cardId) {
        let card = await db
            .collection("employee")
            .doc(cardId)
            .get()
        card = card.data();
        return card
    },

    async uploadCardImage(context, { cardId, file }) {
        const uploadPhoto = () => {
            let fileName = `cards/${cardId}/${cardId}-image.jpg`;
            return storage.ref(fileName).put(file);
        };

        const getDownloadUrl = ref => {
            return ref.getDownloadURL();
        };

        try {
            let upload = await uploadPhoto();
            return await getDownloadUrl(upload.ref);

        } catch (error) {
            throw new Error(error.message);
        }
    },

    async getTechnicalSheets({ commit }) {
        try {
            const techincalsRef = (await database.ref().child("technicalSheets").get()).val();
            if (!techincalsRef) {
                commit('setTechnical', [])
                return null
            }
            const technicaSheets = [];
            for (let id of Object.keys(techincalsRef)) {
                technicaSheets.push({
                    id,
                    ...techincalsRef[id],
                });
            }
            commit('setTechnical', technicaSheets)
        } catch (e) {
            commit('setTechnical', [])
        }
    },

    async createTechnicalSheet({ commit }, technicalSheet) {
        const docRefCard = database.ref('technicalSheets')
        const uid = uuidv4()
        const { image, brand, code } = technicalSheet
        try {
            const url = await uploadFile(uid, image, 'technicalSheets', 'technical')

            const technicalSheet = {
                image: url,
                date: Date.now(),
                active: true,
                brand,
                code
            }

            await docRefCard.child(uid).set(technicalSheet);
            technicalSheet.id = uid;
            commit('createTechnicalSheet', { ...technicalSheet });

        } catch (error) {
            console.log(error);
        }
    },

    async deleteTechnicalSheet({ commit }, { sheetId, imageUrl }) {
        await database.ref('technicalSheets').child(sheetId).remove()
        await deleteFile(imageUrl)
        commit('deleteTechnicalSheet', sheetId)
    },

    async createCatalogue({ commit }, catalogue) {
        const docCatalogueRef = database.ref('catalogues')
        const uid = uuidv4()
        const { file, brand, title } = catalogue

        try {
            const url = await uploadFile(uid, file, 'catalogues', 'pdf')

            const catalogue = {
                file: url,
                date: Date.now(),
                active: true,
                brand,
                title
            }

            await docCatalogueRef.child(uid).set(catalogue);
            docCatalogueRef.id = uid;
            commit('createCatalogue', { ...catalogue });

        } catch (error) {
            console.log(error);
        }
    },

    async getAllCatalogues({ commit }) {
        try {
            const cataloguesRef = (await database.ref().child("catalogues").get()).val();
            if (!cataloguesRef) {
                commit('setCatalogues', [])
                return null
            }
            const catalogues = [];
            for (let id of Object.keys(cataloguesRef)) {
                catalogues.push({
                    id,
                    ...cataloguesRef[id],
                });
            }
            commit('setCatalogues', catalogues)
        } catch (e) {
            commit('setCatalogues', [])
        }
    },

    async deleteCatalogue({ commit }, { catalogueId, fileUrl }) {
        await database.ref('catalogues').child(catalogueId).remove()
        await deleteFile(fileUrl)
        commit('deleteCatalogues', catalogueId)
    },

    async createBranch({ commit }, branch) {
        const docRefBranch = await db.collection("branches").doc();
        console.log(branch)

        const { name, phone, address } = branch
        try {
            const branch = {
                date: Date.now(),
                active: true,
                name,
                phone,
                address
            }

            await docRefBranch.set(branch)
            branch.id = docRefBranch.id;
            commit('createBranch', { ...branch });

        } catch (error) {
            console.log(error);
        }
    },

    async getAllBranches({ commit }) {
        const branchesRef = await db.collection('branches').get();
        const branches = []
        branchesRef.forEach((doc) => {
            const branch = {
                ...doc.data(),
                id: doc.id
            }
            branches.push(branch);
        })

        commit('setBranches', branches);
    },

    async deleteBranch({ commit }, branchId) {
        await db
            .collection("branches")
            .doc(branchId)
            .delete();

        commit('deleteBranch', branchId);
    },

};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
