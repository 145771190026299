export const setStories = (state, stories) => {
    state.stories = stories;
}

export const setBrands = (state, brands) => {
    state.brands = brands;
}

export const setProducts = (state, products) => {
    state.products = products;
}

export const createStory = (state, story) => {
    state.stories = ([story, ...state.stories]);
}

export const createProduct = (state, product) => {
    state.products = ([product, ...state.products]);
}

export const createBrand = (state, brand) => {
    state.brands = ([brand, ...state.brands]);
}

export const updateProduct = (state, product) => {
    const idx = state.products.indexOf(state.products.find(e => e.id === product.id))
    state.products[idx] = product
}

export const deleteStory = (state, id) => {
    state.stories = state.stories.filter(story => story.id != id)
}

export const deleteBrand = (state, id) => {
    state.brands = state.brands.filter(brand => brand.id != id)
}

export const deleteProduct = (state, id) => {
    state.products = state.products.filter(product => product.id != id)
}