import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/main.css";
import './assets/css/main.scss';
import './assets/css/mixins.scss';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueClipboard from 'vue-clipboard2'
import VModal from 'vue-js-modal'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VModal)
Vue.use(VueToast, {
  position: "top"
});
Vue.use(VueClipboard);
Vue.config.productionTip = true;



  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')


