import { db } from "../firebase";

const state = {
  cardsVisits: [],
  cardsVisitsListener: () => {}
};

const getters = {
  getVisitsCard(state){
    return state.cardsVisits
  }
};

const mutations = { 
  setCardsVisitsListener(state, listener){
    if(listener){
      state.setCardsVisitsListener = listener;
    } else {
      state.cardsVisitsListener();
    }
  },

  setVisitsMetrics(state, visits){
      state.cardsVisits = visits;
  },

  createVisitCard(state, { visit, id }){
     visit.id = id;
     state.cardsVisits.push(visit);
  },

  removeVisitCard(state, index){
    state.cardsVisits.splice(index, 1);
  },
  
  updateVisitCard(state, { index, visitData, id }){
    visitData.id = id;
    state.cardsVisits[index] = visitData;
  },


};

const actions = {
  
  async getAllVisitCards({ commit }){
    const query = await db
      .collection("visitas")
      .onSnapshot(doSnapshot);
    
    commit("setCardsVisitsListener", query);

    function doSnapshot(querySnapshot){
      querySnapshot.docChanges().forEach(change => {
        if(change.type === "added"){
          commit("createVisitCard", {
            visit: change.doc.data(),
            id: change.doc.id
          });
        }
        
        if(change.type === "modified"){
          commit("updateVisitCard", {
            index: change.newIndex,
            visitData: change.doc.data(),
            id: change.doc.id
          });
        }


        if(change.type === "removed"){
          commit("removeVisitCard", change.oldIndex);
        }

      })
    }

  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

