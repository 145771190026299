export const getAllStories = (state) => {
    return state.stories
}

export const getAllBrands = (state) => {
    return state.brands
}

export const getAllProducts = (state) => {
    return state.products
}

export const getProductById = (state) => (id) => {
    const product = state.products.find (e => e.id == id)
    if(!product) return null;
    return product
}
