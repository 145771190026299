import { storage } from "../firebase";
const deleteFile = async (urlImage) => {
  try {
        const imageRef = storage.refFromURL(urlImage)
        await imageRef.delete()
    } catch (error) {
        throw new Error(error);
  }
};

export default deleteFile;