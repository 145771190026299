<template>
  <div id="app">
    <transition  name="slide-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>

export default {
}
</script>
