import { database } from "../../firebase";
import { v4 as uuidv4 } from 'uuid';

import uploadFile from "../../helpers/uploadFile";
import deleteFile from "../../helpers/deleteFile";

export const createStory = async ({ commit }, image) => {
    const docRefCard = database.ref('stories')
    const uid = uuidv4()

    try {
        const url = await uploadFile(uid, image, 'stories', 'story')

        const story = { url, date: Date.now(), active: true }
        await docRefCard.child(uid).set(story);
    
        story.id = uid;
        commit('createStory', { ...story })
    } catch (error) {
        console.log(error);
    }
}

export const createBrand = async ({ commit }, brand) => {
  try {
     const docRefCard = database.ref("brands");

      const newBrand = { date: Date.now(), name: brand }
      const docPush = await docRefCard.push(newBrand);
      newBrand.id = docPush.key
      commit("createBrand", newBrand);
  } catch (error) {
      console.log(error);
  }
}

export const createProduct = async ({ commit }, { product, photos }) => {
  const uploadedPhotos = []
  const newProduct = { date: Date.now(), ...product }
  const docRefProduct = database.ref("products");
  const productImagesId = uuidv4()
  try {
    for (let index = 0; index < photos.length; index++) {
      const file = photos[index];
      uploadedPhotos.push(await uploadFile(uuidv4(), file, 'products', 'product', productImagesId))
    }
    newProduct.photos = uploadedPhotos;
    newProduct.imagesId = productImagesId;
    const docpush = await docRefProduct.push(newProduct);
    newProduct.id = docpush.key;
    commit("createProduct", newProduct);
  } catch (error) {
    console.log(error);
  }
}

export const updateProduct = async ({ commit }, { product, photos, id }) => {
  const uploadedPhotos = []
  const newProduct = { updatedAt: Date.now(), ...product }
  delete newProduct.id
  const docRefProduct = database.ref("products");
  const productImagesId = uuidv4()
  try {
    if(photos.length > 0) {

      for (let index = 0; index < product.photos.length; index++) {
        const element = product.photos[index];
        await deleteFile(element);
      }

      for (let index = 0; index < photos.length; index++) {
        const file = photos[index];
        uploadedPhotos.push(await uploadFile(uuidv4(), file, 'products', 'product', productImagesId))
      }
      newProduct.photos = uploadedPhotos;
      newProduct.imagesId = productImagesId;
    }
    await docRefProduct.child(id).update(newProduct);
    newProduct.id = id;
    commit("updateProduct", newProduct);
  } catch (error) {
    console.log(error);
  }
}

export const getAllStories = async ({ commit }) => {
    try {
      const docRefStory = (await database.ref().child("stories").get()).val();
      const stories = [];
      for (let id of Object.keys(docRefStory)) {
        stories.push({
          id,
          ...docRefStory[id],
        });
      }
      commit("setStories", stories);
    } catch (e) {
      commit("setStories", [])
    }
}

export const getAllBrands = async ({ commit }) => {
  try {
    const docRefBrands = (await database.ref().child("brands").get()).val();
    const brands = [];
    for (let id of Object.keys(docRefBrands)) {
      brands.push({
        id,
        ...docRefBrands[id],
      });
    }
    commit("setBrands", brands);
  } catch (e) {
    commit("setBrands", [])
  }
}

export const getAllProducts = async ({ commit }) => {
  try {
    const docRefProduct = (await database.ref().child("products").get()).val();
    const products = [];
    for (let id of Object.keys(docRefProduct)) {
      products.push({
        id,
        ...docRefProduct[id],
      });
    }
    commit("setProducts", products);
  } catch (e) {
    commit("setProducts", [])
  }
}


export const deleteStory = async ({ commit }, { id, urlImage}) => {
  await database.ref('stories').child(id).remove()
  await deleteFile(urlImage)
  commit('deleteStory', id)
}

export const deleteBrand = async ({ commit }, id) => {
  await database.ref('brands').child(id).remove()
  commit('deleteBrand', id)
}

export const deleteProduct = async ({ commit }, { id, images }) => {
  await database.ref('products').child(id).remove()
  for (let index = 0; index < images.length; index++) {
    const element = images[index];
    await deleteFile(element);
  }
  commit('deleteProduct', id)
}
