import {auth} from '../firebase';


const state = {
    user : {},
};

const getters = {

};

const actions = {
    async doRegister( {commit}, {name, email, password} ){
        await auth.createUserWithEmailAndPassword(email, password);
        const user = auth.currentUser;
        await user.updateProfile({
          displayName: name
        });
        commit("setUser", user);
      },
  
      async doLogin( {commit}, {email, password}){
        await auth.signInWithEmailAndPassword(email, password);
        const user = auth.currentUser;
        commit("setUser", user);
      },
  
      async doLogOut({commit}){
        await auth.signOut();
        commit("setUser", null);
      },
  
      getCurrentUser(){
        return new Promise((resolve, reject) =>{
          const unsubscribe = auth.onAuthStateChanged(
            user => {
              unsubscribe();
              resolve(user);
            },
            () => {
              reject();
            }
          );
        });
      }
};

const mutations = {
    setUser(state, user){
        state.user = user;
      },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};